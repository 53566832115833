import React from 'react';
import PolImg from 'src/assets/images/chapter_4/sub_3/pol.png';
import PlFlag from 'src/assets/images/chapter_4/sub_3/pl_flag.png';
import RusImg from 'src/assets/images/chapter_4/sub_3/rus.png';
import RusFlag from 'src/assets/images/chapter_4/sub_3/rus_flag.png';
import { useInView } from 'react-intersection-observer';
import {
  Container,
  Line,
  Country,
  Counter,
  FrontCounter,
  Wrapper,
  FlagImg,
} from './styles';

const ArmyAnimation = ({ query }) => {
  const {
    country1, country2, counter1, counter2, frontCounter1, frontCounter2,
  } = query.animationInfo.edges[0].node.frontmatter;
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  const [ref2, inView2] = useInView({
    threshold: 0.5,
  });
  return (
    <>
      <Container ref={ref}>
        <FlagImg src={PlFlag} alt="flag-pl" />
        <Line visible={inView} />
        <Wrapper visible={inView}>
          <Country>{country1}</Country>
          <Counter>{counter1}</Counter>
          <img src={PolImg} alt="poland" />
          <FrontCounter>{frontCounter1}</FrontCounter>
        </Wrapper>
      </Container>
      <Container ref={ref2}>
        <FlagImg src={RusFlag} alt="flag-flag" />
        <Line visible={inView2} />
        <Wrapper visible={inView2}>
          <Country>{country2}</Country>
          <Counter>{counter2}</Counter>
          <img src={RusImg} alt="rus" />
          <FrontCounter>{frontCounter2}</FrontCounter>
        </Wrapper>
      </Container>
    </>
  );
};

export default ArmyAnimation;
