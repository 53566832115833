import styled, { css, keyframes } from 'styled-components';

const showSymbol = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

export const ArmiesWrapper = styled.div`
  position: relative;
  width: 100vw;
  left: 3vw;
  top: 2vh;
  display: flex;
  justify-content: center;
`;

export const Side = styled.div`
  position: absolute;
  width: 62px;
  height: 62px;
  ${({ leftSide }) => leftSide
        && css`
        top: 46%;
        /* left: 36%; */
        right: 60%;
        background-image: url(${leftSide.symbol});
        background-repeat: no-repeat;
        opacity: 0;
        animation: ${showSymbol} 1.5s cubic-bezier(0.820, 0.000, 0.195, 1.000) 1s forwards;
    `}
    ${({ rightSide }) => rightSide
        && css`
        top: 24%;
        left: 73%;
        background-image: url(${rightSide.symbol});
        background-repeat: no-repeat;
        opacity: 0;
        animation: ${showSymbol} 1.5s cubic-bezier(0.820, 0.000, 0.195, 1.000) 3s forwards;
    `}
`;


export const AnimationContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;
