import React, { useEffect, createRef } from 'react';
import lottie from 'lottie-web/build/player/lottie_svg';
import { getLangFromPath } from 'src/utils/index.js';
import { ArmiesWrapper, AnimationContainer } from './styles';

const ArmiesAnimation = () => {
  const animationContainer = createRef();
  const lang = getLangFromPath();
  const animation = lang ? require(`./data_${lang}.json`) : require('./data_PL.json');

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
      },
    });
    setTimeout(() => { anim.play(); }, 200);
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <ArmiesWrapper>
      <AnimationContainer ref={animationContainer} />
    </ArmiesWrapper>
  );
};

export default ArmiesAnimation;
