import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import ArmiesAnimation from 'src/components/desktop/armiesAnimation/armiesAnimation';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { SlideLarge } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_3/1-bg.jpg"}) {
      childImageSharp {
          fixed(width: 1920, quality: 85) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    },
    scenelayer1: file(relativePath: { eq: "chapter_4/sub_3/2-bg.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
    scenelayer2: file(relativePath: { eq: "chapter_4/sub_3/3-bg.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
`);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: 20, y: 10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fixed }}
        />
        <Mordo
          scenelayer
          scene={1}
          move={{ x: -10, y: -10 }}
          background={{ image: assetQuery.scenelayer1.childImageSharp.fluid }}
        />
        <Mordo
          flexi
          scene={2}
          move={{ x: -14, y: -10 }}
          background={{ color: 'transparent' }}
        >
          <ArmiesAnimation />
        </Mordo>
        <Mordo
          flexi={{ alignSelf: 'flex-start' }}
          scene={3}
          move={{ x: 5, y: 5 }}
        >
          <div css={{
            marginTop: '10%',
          }}
          >
            <LeftColContent body={query.leftColumn.body} />
          </div>
        </Mordo>
        <Mordo
          scenelayer={{ size: 'cover' }}
          scene={4}
          move={{ x: 10, y: -20 }}
          background={{ image: assetQuery.scenelayer2.childImageSharp.fluid }}
        />
      </ParallaxMordo>
    </SlideLarge>
  );
};

export default Slide;
