import styled, { css, keyframes } from 'styled-components';

const lineEnter = keyframes`
    from {
        transform: translate3d(-3rem, 0, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
`;

const opacityEnter = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const lineAnimation = ({ visible }) => {
  if (visible) {
    return css`
      ${lineEnter} 1s linear 500ms forwards
    `;
  }
  return null;
};

const opacityAnimation = ({ visible }) => {
  if (visible) {
    return css`
      ${opacityEnter} 500ms linear 1000ms forwards
    `;
  }
  return null;
};

export const Container = styled.div`
  margin-left: 3rem;
  position: relative;
  padding: 1rem;
  color: #f5f1ec;
  font-size: 1.25rem;
  font-weight: 400;
  padding-top: 5rem;
  margin-bottom: 4rem;
`;

export const Line = styled.div`
  background: white;
  height: 1px;
  width: 3rem;
  position: absolute;
  top: 8.5rem;
  left: -3rem;
  transform: translate3d(-3rem, 0, 0);
  animation: ${lineAnimation};
`;

export const Wrapper = styled.div`
  animation: ${opacityAnimation};
  opacity: 0;
`;

export const Country = styled.p`
  font-family: "Abril Fatface";
`;

export const Counter = styled.p`
  font-family: "Red Hat Display";
`;

export const FrontCounter = styled.p`
  color: #fdfdfd;
  font-family: "Red Hat Display";
  font-size: 0.75rem;
`;

export const FlagImg = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(calc(-50% - 1.5rem));
  top: -1rem;
`;
