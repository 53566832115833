import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import { BlockContainer, ChpaterTitle } from '../../_styles';
import ArmyAnimation from '../../../../../components/mobile/armyAnimation/armyAnimation';

const Slide = ({ query, title }) => (
  <BlockContainer height="1250px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <ChpaterTitle marginTop="8rem">{title}</ChpaterTitle>
      <TextAnimation containerStyle={{ height: '28%', marginTop: '1rem' }} body={query.mdx.body} />
      <ArmyAnimation query={query} />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
